import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from '../reducers';

export default function configureStore() {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    //const composeEnhancer = compose;

    return createStore(
        combineReducers({
            ...reducers
        }),
        composeEnhancer(applyMiddleware(thunk)),
    );
}