import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import axios from 'axios';
import './App.scss';
import siteDetails from './siteDetails';


import jwt_decode from 'jwt-decode';


const loading = () => <div style={{ alignContent: 'center', justifyContent: 'center', display: 'flex' }}><img src={'./assets/img/Loading2.gif'} alt="loading..." style={{
  width: '150px',
  position: 'center',
  // zIndex: 9999,
  // left: '40%',
  // top: '50%',
  display: 'flex',
  alignSelf: 'center',
  // display: 'block',
}} /></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const PaymentLink = React.lazy(() => import('./views/PG'));
const Success = React.lazy(() => import('./views/Pages/Success'));
const Failed = React.lazy(() => import('./views/Pages/Failed'));
const Status = React.lazy(() => import('./views/Pages/Status'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginRender: '',
      isAuth: true,
      userRole: ''
    }
    /* redirecting to https */
    if (window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace('http', 'https');
    }
    this.authSet = this.authSet.bind(this);
  }


  authSet() {
    localStorage.removeItem('authToken');
    this.setState({
      isAuth: false
    })
  }

  authCheck = () => {
    try {
      if (localStorage.authToken) {
        const axiosConfig = {
          headers: {
            'x-auth-token': localStorage.authToken
          }
        }
        axios.get(siteDetails.domain + "/login/validToken", axiosConfig).then(res => {
          if (res.data.message) {
            this.setState({ isAuth: true })
          }
          else {
            this.setState({ isAuth: false })
          }
        }).catch(err => {
          this.setState({ isAuth: false })
        });
      } else {
        this.setState({ isAuth: false })
      }
    }
    catch (err) {
      this.setState({ isAuth: false })
    }
  }

  componentDidMount() {
    this.authCheck()
    let BrowserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    localStorage.setItem('BrowserTimezone', BrowserTimezone);
    this.checkExpiry()
  }



  loginRender = () => {
    this.authCheck();
  }
  getRole = () => {
    if (localStorage.authToken) {
      const decoded = jwt_decode(localStorage.authToken)
      return decoded.user.role
    }
    else {
      return 0
    }
  }
  checkExpiry = () => {
    if (localStorage.authToken) {
      const decoded = jwt_decode(localStorage.authToken)
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('authToken');
        this.setState({ isAuth: false })

      } else {
        // console.log(decoded.user.role, decoded.user.uid, "token still valid")
        console.log("live")

      }
    }
    else {
      // useHistory().push("/login");
      return 0
    }
  }


  render() {
    const isAuthenticated = this.state.isAuth;
    // console.log(isAuthenticated);
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>

            <Route exact path="/login" name="Login Page"
              render={
                props => {
                  if (!isAuthenticated) {
                    return <Login loginRender={this.loginRender} {...props} />
                  } else {
                    return <Redirect to="/" />
                  }
                }
              }
            />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/success" name="Success Page" render={props => <Success {...props} />} />
            <Route exact path="/failed" name="Failed Page" render={props => <Failed {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/pg/:id" name="Payment Link" render={props => <PaymentLink {...props} />} />
            <Route exact path="/paymentStatus/:orderId/:paymentId" name="Payment Status Page" render={props => <Status {...props} />} />


            <Route
              exact path="/*" name="Home"
              render={
                props => {
                  if (isAuthenticated) {
                    return <DefaultLayout authSet={this.authSet} {...props} role={this.getRole()} />
                  } else {
                    return <Redirect to="/login" />
                  }
                }
              }
            />

          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
