import axios from 'axios';
import siteDetails from '../siteDetails';
const CHART_DATA = 'DASHBOARD/CHART_DATA';

const setChartData = (chart_data, label_days) => ({
    type: CHART_DATA,
    chart_data,
    label_days
});

export function fetchArticleDetails(cal_type, user = '', timezone, gatewayName = '') {
    // console.log(cal_type, timezone, gatewayName,  'reducer')
    return function (dispatch) {
        let url = siteDetails.domain + "/payments/dashboardTimezone?type=" + cal_type;
        if (user !== '') {
            url += "&uid=" + user;
        }

        let t = { timezone: timezone, gatewayName: gatewayName };
        if (gatewayName === 'ALL') {
            t = { timezone: timezone }
        }
        return axios.post(url, t)
            .then(({ data }) => {
                dispatch(setChartData(data.data, data.days));
            });
    };
}


export default function reducer(state = {
    chart_data: [],
    label_days: []
}, action) {
    switch (action.type) {
        case CHART_DATA:
            return {
                ...state,
                chart_data: action.chart_data,
                label_days: action.label_days
            }

        default:
            return state;
    }
}