const siteDetails = {
    domain: "https://client.paysecurepro.com:8443",
    token: {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('authToken')
        }
    }

}
export default siteDetails;